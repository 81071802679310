<template>
  <div class="white--text">
    <!-- Heading  -->
    <heading1TextEffect fontSize="48px" text="I nostri clienti" />
    <!-- Body -->
    <div class="pa-6">
      <v-row>
        <v-col cols="6" md="4" v-for="item in 33" :key="item">
          <v-card height="200">
            <v-card-text>
              <v-img max-height="170" :src="dummy_logos[item]" contain />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      {{ getLogo() }}
    </div>
  </div>
</template>

<script>
import heading1TextEffect from "@/components/landings/heading1TextEffect.vue";
import "whatwg-fetch";

export default {
  props: {},
  components: {
    heading1TextEffect,
  },
  data: () => ({
    dummy_logos: [],
  }),
  methods: {
    getLogo() {
      const url =
        "https://api.unsplash.com/search/photos?query=logo&per_page=18&client_id=GF3SvNg20sf_MK5gz3rVHuLbcO8zjdapbQ9dbvLLj2g";
      fetch(url)
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          for (let i = 0; i < data.results.length; i++) {
            // console.log(data.results[i].urls.thumb);
            this.dummy_logos.push(data.results[i].urls.thumb);
          }
        });
    },
  },
  mounted() {
    // window.scrollTo(0,0);
  },
  created() {},
  computed: {},
  watch: {},
};
</script>

<style>
</style>
